import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import AuthContent from 'components/AuthContent'
import { Formik } from 'formik'
import UPS from '../components/API/UPS/UPSRates'

const BasicExample = ({ location }) => (
	<Layout>
		<UPS />
	</Layout>
)

export default BasicExample