import React, { Component } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

// here want to make a general input component that can take props and create input fields
// label, value, flexBasis? probably, 

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
  },
});

export class Input extends Component {
  state = {
    inputValue: ''
  };
  componentDidMount() {
    const { defaultValue } = this.props
    this.setState({ inputValue: defaultValue })
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
    /* this.props.params === 'length' || 'width' || 'height' ?
      this.props.sendInput({ dimensions: { [prop]: event.target.value } }) : */
    this.props.sendInput({ [prop]: event.target.value })
  };

  render() {
    const { classes, adornment, style, label, params, defaultValue } = this.props
    return (
      <div className={style}>
        <TextField
          id="outlined-adornment-weight"
          className={classNames(classes.margin, classes.flexBasis)}
          variant="outlined"
          label={label}
          value={defaultValue || this.state.inputValue}
          defaultValue={defaultValue}
          onChange={this.handleChange(params)}
          helperText=""
          InputProps={this.props.adornment ? {
            endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
          } :
            null
          }
        />
      </div>
    )
  }
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Input)