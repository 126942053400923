import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 'fill',
  },
});

const packagingTypeCodes = [
  {
    value: 'M4',
    label: 'UPS Expedited Mail Innovations'
  },
  {
    value: '02',
    label: 'UPS 2nd Day Air',
  },
  {
    value: '14',
    label: 'UPS Next Day Air Early',
  },
  {
    value: '01',
    label: 'UPS Next Day Air',
  },
  {
    value: '13',
    label: 'UPS Next Day Air Saver',
  },
  {
    value: '59',
    label: 'UPS 2nd Day Air A.M.'
  },
  {
    value: '12',
    label: 'UPS 3 Day Select'
  },
  {
    value: '03',
    label: 'UPS Ground'
  }
];

export class PackagingTypeCodeDropdown extends Component {
  state = {
    country: '',
    packagingTypeCode: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <TextField
          id='filled-select-country'
          select
          label='Service'
          className={classes.textField}
          value={this.state.packagingTypeCode}
          onChange={this.handleChange('packagingTypeCode')}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          helperText='Please select service'
          margin='normal'
        >
          {packagingTypeCodes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    )
  }
}

PackagingTypeCodeDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PackagingTypeCodeDropdown)