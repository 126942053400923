import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const countries = [
  {
    value: 'US',
    label: 'United States',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'BR',
    label: 'Brazil',
  },
  {
    value: 'CN',
    label: 'China',
  },
  {
    value: 'DR',
    label: 'Dominican Republic'
  },
  {
    value: 'ES',
    label: 'Estonia'
  }
];

export class CountriesDropdown extends Component {
  state = {
    country: 'United States',
    countryCode: 'US'
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, });
    this.props.sendInput({ [prop]: event.target.value })
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <TextField
          id='filled-select-country'
          select
          label='Select Country'
          className={classes.textField}
          value={this.state.country}
          onChange={this.handleChange('country')}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          helperText='Please select your country'
          margin='normal'
          defaultValue={this.props.defaultValue}
        >
          {countries.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    )
  }
}

CountriesDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CountriesDropdown)