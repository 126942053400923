import React from 'react'
import axios from 'axios'
//import base64Img from 'base64-img'
//import pdfmake from 'pdfmake/build/pdfmake'
import Input from './common/Input'
import CountriesDropdown from './uncommon/Countries'
import PackagingTypeCodeDropdown from './uncommon/PackagingTypeCode'
import { withStyles } from '@material-ui/core/styles'
import GetButton from './common/GetButton'

import upsCodes from './ups-service-codes'
//require('dotenv').config()

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 20
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
  },
});

export class UPSRates extends React.Component {
  state = {
    shipperZip: '',
    shipperCountryCode: '',
    shipToZip: '',
    shipToCountryCode: '',
    packagingTypeCode: '',
    dimensions: {
      unitOfMeasurement: '',
      length: '',
      width: '',
      height: ''
    },
    packageWeight: {
      unitOfMeasurement: '',
      weight: ''
    },
    formSubmitted: false,
    response: null,
    serviceCode: '',
    graphicImage: '',
    length: '',
    width: '',
    height: '',
    weight: '',
  }

  defaultValues = () => {
    console.log('clicked default values button')
    this.setState({
      shipperZip: '11572',
      shipperCountryCode: 'US',
      shipToZip: '11358',
      shipToCountryCode: 'US',
      packagingTypeCode: '02',
      dimensions: {
        unitOfMeasurement: 'IN',
        length: '10',
        width: '10',
        height: '10'
      },
      packageWeight: {
        unitOfMeasurement: 'LBS',
        weight: '2'
      },
      serviceCode: '02',
      length: '10',
      width: '10',
      height: '10',
      weight: '2'
    })
  }

  UPSApi = (
    shipperZip,
    shipperCountryCode,
    shipToZip,
    shipToCountryCode,
    packagingTypeCode,
    dimensionsUnitOfMeasurement,
    length,
    width,
    height,
    packageWeightUnitOfMeasurement,
    weight) => {
    let url = 'https://cors-anywhere.herokuapp.com/https://onlinetools.ups.com/ship/v1801/rating/Shop'
    let options = {
      method: 'post',
      url: url,
      headers: {
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'POST',
        'Content-Type': 'application/json',

        'Access-Control-Allow-Origin': '*',
        'AccessLicenseNumber': '4D4DCE59B84244CD',
        'username': 'niwopartners',
        'password': 'Xhy578208'
      },
      data: {
        'RateRequest': {
          'Request': {
            'RequestOption': 'Rate'
          },
          'Shipment': {
            'Shipper': {
              'Address': {
                'PostalCode': shipperZip,
                'CountryCode': shipperCountryCode
              }
            },
            'ShipTo': {
              'Address': {
                'PostalCode': shipToZip,
                'CountryCode': shipToCountryCode
              }
            },
            'Package': {
              'PackagingType': {
                'Code': packagingTypeCode
              },
              'Dimensions': {
                'UnitOfMeasurement': {
                  'Code': dimensionsUnitOfMeasurement
                },
                'Length': length,
                'Width': width,
                'Height': height
              },
              'PackageWeight': {
                'UnitOfMeasurement': {
                  'Code': packageWeightUnitOfMeasurement
                },
                'Weight': weight
              }
            }
          }
        }
      }
    }
    let response = async () => await axios(options)
      .then(res => {
        console.log('rate response: ', res);
        return this.handleResponse(res)
      })
      .catch(err => (console.log(err)))
    response()
  }

  UPSLabelApi = (
    shipperName,
    shipperAddressLine,
    shipperCity,
    shipperZip,
    shipperCountryCode,
    shipToName,
    shipToAddress,
    shipToCity,
    shipperStateProvinceCode,
    shipToStateProvinceCode,
    shipToZip,
    shipToCountryCode,
    serviceCode,
    packagingCode,
    packageWeightUnitOfMeasurement,
    weight) => {
    /* don't forget to set cors prefix here for deployments */
    //https://cors-anywhere.herokuapp.com/
    let url = 'https://cors-anywhere.herokuapp.com/https://onlinetools.ups.com/ship/v1801/shipments?additionaladdressvalidation=oceanside'
    let options = {
      method: 'post',
      url: url,
      headers: {
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'POST',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'AccessLicenseNumber': '4D4DCE59B84244CD',
        'username': 'niwopartners',
        'password': 'Xhy578208'
      },
      data: {
        'ShipmentRequest': {
          'Request': {
            'RequestOption': 'nonvalidate'
          },
          'Shipment': {
            'Description': {
              'Value': 'description here'
            },
            'Shipper': {
              'Name': shipperName,
              'ShipperNumber': '089A2A',
              'Address': {
                'AddressLine': shipperAddressLine,
                'City': shipperCity,
                'StateProvinceCode': shipperStateProvinceCode,
                'PostalCode': shipperZip,
                'CountryCode': shipperCountryCode
              }
            },
            'ShipTo': {
              'Name': shipToName,
              'Address': {
                'AddressLine': shipToAddress,
                'City': shipToCity,
                'StateProvinceCode': shipToStateProvinceCode,
                'PostalCode': shipToZip,
                'CountryCode': shipToCountryCode
              }
            },
            'PaymentInformation': {
              'ShipmentCharge': {
                'Type': '01',
                'BillShipper': {
                  'AccountNumber': '089A2A'
                }
              }
            },
            'Service': {
              /* fill in values for this eventually */
              'Code': serviceCode
            },

            'Package': {
              'Packaging': {
                /* fill in values for this eventually */
                'Code': packagingCode,

              },
              'PackageWeight': {
                'UnitOfMeasurement': {
                  'Code': packageWeightUnitOfMeasurement
                },
                'Weight': weight
              }
            },
            'ItemizedChargesRequestedIndicator': '',
            'RatingMethodRequestedIndicator': '',
            'TaxInformationIndicator': '',
            'ShipmentRatingOptions': {
              'NegotiatedRatesIndicator': ''
            },
            'PackageID': {
              'Value': 'abcdef'
            }
          },
          'LabelSpecification': {
            'LabelImageFormat': {
              'Code': 'GIF'
            },
            'LabelStockSize': {
              'Height': '8',
              'Width': '4'
            }
          },
          /*           'ReceiptSpecification': {
                      'ImageFormat': {
                        'Code': 'ZPL'
                      }
                    } */
        }
      }
    }
    let response = async () => await axios(options)
      .then(res => {
        //wait me console.log('label response: ', res)
        console.log(res.data.ShipmentResponse.ShipmentResults.PackageResults)
        // this.handleResponse(res)
        const graphicImage = 'data:image/gif;base64,' + res.data.ShipmentResponse.ShipmentResults.PackageResults.ShippingLabel.GraphicImage
        console.log('shipment response: ', res);
        // const graphicImage = 'data:image/gif;base64,' + graphicImageBase64;
        this.setState(
          prev => (
            {
              ...prev,
              graphicImage: graphicImage
            }
          )
        )
      })
      .catch(err => (console.log(err)))
    response()
  }

  // res.data.RateResponse.RatedShipment[0].TotalCharges.MonetaryValue
  /*   handleResponse = (res) => {
      let rates = res.data.RateResponse.RatedShipment
      rates.map(i => (console.log(i.TotalCharges.MonetaryValue, i.BillingWeight.Weight, upsCodes[i.Service.Code])))
    } */

  //trying to create a function that stores the service code below. need to think about sync.
  handleResponse = res => {
    let rates = res.data.RateResponse.RatedShipment || null
    this.setState(prev => (
      {
        ...prev,
        response: rates
      }
    ))
  }

  showResponse = (res) => (res ? (res.map(i => (
    <tr>
      <td>{'$ ' + i.TotalCharges.MonetaryValue}</td>
      {/* <td>{i.BillingWeight.Weight}</td> */}
      <td>{upsCodes[i.Service.Code]}</td>
      <td style={{ textAlign: 'center' }}>{i.GuaranteedDelivery ? i.GuaranteedDelivery.BusinessDaysInTransit : '-'}</td>
      <td style={{ textAlign: 'center' }}>{i.GuaranteedDelivery ? i.GuaranteedDelivery.DeliveryByTime : '-'}</td>
      <td style={{ textAlign: 'center' }}>
        <button onClick={this.handlePrintLabelButton}>Print</button>
      </td>
    </tr>
  ))
  ) : (null)
  )

  handlePrintLabelButton = e => {
    e.preventDefault()
    let choosing = async () =>
      await axios(this.setState(
        prev => (
          {
            prev,
            serviceCode: '02'
          }
        )
      ))
        .then(() =>
          this.UPSLabelApi(
            'niwopartners_UPS',
            '3401 Lawson Blvd',
            'Oceanside',
            this.state.shipperZip,
            this.state.shipperCountryCode,
            'test customer',
            '45-15 162nd st',
            'Flushing',
            'NY',
            'NY',
            this.state.shipToZip,
            this.state.shipToCountryCode,
            this.state.serviceCode,
            '02',
            this.state.packageWeight.unitOfMeasurement,
            this.state.packageWeight.weight
          )
        )
        .then(() => {
          console.log('printing pdf')
          /* const docDefinition = {
            content: [
              {
                image: this.state.graphicImage
              }
            ]
          }
          const label = pdfmake.createPdf(docDefinition);
          label.write('newlabel.png') */
        })
        .catch(err => (console.log(err)))
    choosing()
  }

  // Gets quotes for rates
  handleSubmit = e => {
    e.preventDefault()
    this.UPSApi(
      this.state.shipperZip,
      this.state.shipperCountryCode,
      this.state.shipToZip,
      this.state.shipToCountryCode,
      this.state.packagingTypeCode,
      this.state.dimensions.unitOfMeasurement,
      this.state.length,
      this.state.width,
      this.state.height,
      this.state.packageWeight.unitOfMeasurement,
      this.state.weight
    )
    this.setState(prev => ({ ...prev, formSubmitted: true }))

    this.UPSLabelApi(
      'niwoparters_UPS'/* shipperName */,
      '3401 Lawson Blvd'/* shipperAddressLine */,
      'Oceanside'/* shipperCity */,
      this.state.shipperZip,
      this.state.shipperCountryCode,
      'test customer'/* shipToName */,
      '45-15 162nd St'/* shipToAddress */,
      'Flushing'/* shipToCity */,
      'NY'/* shipperStateProvinceCode */,
      'NY'/* shipToStateProvinceCode */,
      this.state.shipToZip,
      this.state.shipToCountryCode,
      this.state.serviceCode,
      '02',
      this.state.packageWeight.unitOfMeasurement,
      this.state.packageWeight.weight
    )
  }

  getInput = props => {
    this.setState(props)
  }

  render() {
    const { classes } = this.props
    const { shipperZip, shipperCountryCode, shipToZip, shipToCountryCode, packagingTypeCode, serviceCode, length, width, height, weight } = this.state
    return (
      <div>
        <form onSubmit={this.handleSubmit} className={classes.root}>
          <Input
            label='Ship From Zip Code'
            params='shipperZip'
            sendInput={this.getInput}
            defaultValue={shipperZip}
          />
          <CountriesDropdown
            params='shipperCountryCode'
            sendInput={this.getInput}
            defaultValue='United States'
          />

          <Input
            label='Ship To Zip Code'
            params='shipToZip'
            sendInput={this.getInput}
            defaultValue={shipToZip}
          />
          <CountriesDropdown
            params='shipToCountryCode'
            sendInput={this.getInput}
            defaultValue='United States'
          />
          <PackagingTypeCodeDropdown
            sendInput={this.getInput}
            defaultValue={packagingTypeCode}
          />
          <Input
            label='Length'
            adornment='inches'
            params='length'
            sendInput={this.getInput}
            defaultValue={length}
          />
          <Input
            label='Width'
            adornment='inches'
            params='width'
            sendInput={this.getInput}
            defaultValue={width}
          />
          <Input
            label='Height'
            adornment='inches'
            params='height'
            sendInput={this.getInput}
            defaultValue={height}
          />
          <Input
            label='Weight'
            adornment='Lbs'
            params='weight'
            sendInput={this.getInput}
            defaultValue={weight}
          />
          <div>
            <GetButton value="Get Quotes" />
          </div>
        </form>

        <button type='button' onClick={this.defaultValues}>default values</button>

        {this.state.formSubmitted ?
          (
            <table>
              <thead>
                <tr>
                  <th style={{ padding: '0 20px 0 20px' }}>Price</th>
                  {/* <th>Weight</th> */}
                  <th style={{ padding: '0 20px 0 20px' }}>Service</th>
                  <th style={{ padding: '0 20px 0 20px' }}>Days in Transit</th>
                  <th style={{ padding: '0 20px 0 20px' }}>Delivery By</th>
                  <th style={{ padding: '0 20px 0 20px' }}>Print Label</th>
                </tr>
              </thead>
              <tbody>
                {this.showResponse(this.state.response)}
              </tbody>
            </table>
          ) : null
        }

        {this.state.graphicImage ?
          (
            <img src={this.state.graphicImage} />
          )
          :
          null
        }

        {/*         {this.state.graphicImage ?
          (
            pdfmake.createPdf({ content: [{ image: this.state.graphicImage }] })
          ) : null} */}
        {console.log(this.state.graphicImage)}
      </div>
    )
  }
}

export default withStyles(styles)(UPSRates)